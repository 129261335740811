





























































































































































































































































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  // @Prop({ required: true, default: null }) vm!: MarketItemViewModel
}
